<template>
  <LoadingSpinner :loading="isLoading" />
  <div>
    <h1>Dashboard</h1>
   
    <div class="my-5">
      <div class="row align-items-end filter">
        <!--<div class="col-3">
          <strong class="mb-1 d-block">Modalidade de envio</strong>
          <select class="form-select  mb-3" aria-label=".form-select-lg example">
            <option 
            v-for="item in shippingMethods"
            :key="item.id"
            :value="item.attributes.id_loja_integrada"            
            >{{ item.attributes.Nome }}</option>
            
          </select>
        </div>
        -->
        <div class="col-3">
          <strong class="mb-1 d-block">Data inicial</strong>
          <input type="date" v-model="start_date" class="form-select  mb-3"/>
        </div>
        <div class="col-3">
          <strong class="mb-1 d-block">Data final</strong>
          <input type="date" v-model="end_date" class="form-select  mb-3"/>
        </div>
        <div class="col-3">
          <button
          class="btn btn-primary"
          @click="updateChart()"
          >Atualizar</button>
        </div>
      </div>
    </div>
    
    <div class="row">
      <div 
      v-for="(item, index) in apx_chartData.labels"
      :key="index"
      class="col-12 col-md-4">        
        <div class="panel">
          <span class="d-block mb-2">{{ apx_chartData.datasets[0].data[index] }}</span>
          <small>{{ item }}</small>

          <div>
            <Bar
              v-if="shippingMethods[index] && shippingMethods[index].attributes.Nome == item && shippingMethods[index].chart.apx_chartData"
              :options="shippingMethods[index].chart.apx_chartOptions"
              :data="shippingMethods[index].chart.apx_chartData"
            />
          </div>
        </div>
      </div>
      
    </div>
    <div class="row mt-5">
      <div class="col-12">
        <Bar
          v-if="showChart"
          :options="chartOptions"
          :data="chartData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { mapActions } from 'vuex';
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'TheDashboard',
  components: { Bar,
    LoadingSpinner },
  data() {
    return {
      start_date: new Date().toISOString().slice(0,10),
      end_date: new Date().toISOString().slice(0,10),
      apx_chartData: {
        labels: [],
        datasets: [ { 
          label:'Agendamentos',
          backgroundColor: ['#009E6C'],
          data: [] 
        } ]
      },
      apx_chartOptions: {
        responsive: true,        
      },
      
      shippingMethods:false,
      isLoading:true,
      showChart:false
    }
  },
  computed: {
    chartData: function() { return this.apx_chartData },
    chartOptions:function(){ return this.apx_chartOptions }
  },
  methods:{
    ...mapActions(['performGet','performPost','performDelete','api_lang']),
    async getItemData(index){
      

      if (this.apx_chartData.datasets && this.apx_chartData.datasets.data[index]) {
        return await this.apx_chartData.datasets.data[index];
      } else {
        return 0;
      }
    },
    async getShippingMethods(){
      const ctx = await this.$store.dispatch('performGet', {
        path: 'formas-de-envios/?populate=deep'
      })

      if(ctx.data){
        this.shippingMethods = ctx.data
        // for(let item in this.shippingMethods){
        //   let shippingMethod = this.shippingMethods[item]

        //   console.log('string', 'agendamentos/?_limit=0&filters[formas_de_envio][$eq]=' + shippingMethod.attributes.id_loja_integrada + '&filters[schedule_date][$lte]=' + this.end_date + '&filters[schedule_date][$gte]=' + this.start_date)
          
        //   let query = await this.$store.dispatch('performGet', {
        //     path: 'agendamentos/?_limit=0&filters[formas_de_envio][$eq]=' + shippingMethod.attributes.id_loja_integrada + '&filters[schedule_date][$lte]=' + this.end_date + '&filters[schedule_date][$gte]=' + this.start_date
        //   })

        //   if(query && query.meta && query.meta.pagination){
        //     console.log(query.data)
        //     this.apx_chartData.datasets[0].data.push(query.meta.pagination.total)
        //     this.apx_chartData.labels.push(this.shippingMethods[item].attributes.Nome)
            
        //   }else{
        //     this.apx_chartData.datasets[0].data.push(0)
        //     this.apx_chartData.labels.push(this.shippingMethods[item].attributes.Nome)            
        //   }
          
      
        // }
        
        // console.log(this.apx_chartData.labels)
        // console.log(this.apx_chartData.datasets)
        //this.apx_chartData.datasets[0].data = [0,0,0,1,0,0,0,]
        
        

      }

      

      this.isLoading = false

      console.log(this.shippingMethods)
    },
    getDates(startDate, endDate) {
      console.log(startDate, endDate)
      let dates = [];
      let formatStartDate = new Date(startDate);
      let formatEndDate = new Date(endDate);
      dates.push(this.formatDate(formatStartDate.toISOString().slice(0,10)))
      while (formatStartDate <= formatEndDate) {        
        formatStartDate.setDate(formatStartDate.getDate() + 1)
        dates.push(this.formatDate(formatStartDate.toISOString().slice(0,10)))
      }
      return dates;
    },
    formatDate(date){
      let format = date.split('-');
      let formatedDate = format[2] + '/' + format[1] + '/' + format[0]
      return formatedDate
    },  
    unformatDate(date){
      let format = date.split('/');
      let formatedDate = format[2] + '-' + format[1] + '-' + format[0]
      return formatedDate
    },  
    async updateChart(){
      this.isLoading = true
      this.showChart = false
     
      this.apx_chartData.datasets[0].data = []
      this.apx_chartData.labels = []

      let dateList = this.getDates(this.start_date, this.end_date);
      dateList.forEach(date => {
        console.log(date);
      });

      for(let item in this.shippingMethods){
        
        
        let shippingMethod = this.shippingMethods[item]

        //console.log('string', 'agendamentos/?_limit=100&filters[formas_de_envio][$eq]=' + shippingMethod.attributes.id_loja_integrada + '&filters[schedule_date][$lte]=' + this.end_date + '&filters[schedule_date][$gte]=' + this.start_date)
        
        let query = await this.$store.dispatch('performGet', {
          path: 'agendamentos/?_limit=100&fields[1]=schedule_date&filters[formas_de_envio][$eq]=' + shippingMethod.attributes.id_loja_integrada + '&filters[schedule_date][$lte]=' + this.end_date + '&filters[schedule_date][$gte]=' + this.start_date
        })
        this.shippingMethods[item].schedules = query
        if(query && query.meta && query.meta.pagination){
          this.apx_chartData.datasets[0].data.push(query.meta.pagination.total)
          this.apx_chartData.labels.push(this.shippingMethods[item].attributes.Nome)
          
        }else{
          this.apx_chartData.datasets[0].data.push(0)
          this.apx_chartData.labels.push(this.shippingMethods[item].attributes.Nome)            
        }

        

        this.shippingMethods[item].chart = {
          apx_chartData: {
            labels: dateList,
            datasets: [ { 
              label:'Agendamentos',
              backgroundColor: ['#009E6C'],
              data: [] 
            } ]
          },
          apx_chartOptions: {
            legend: {
                  display: false
                },
            responsive: true,
            plugins: {
                legend: {
                  display: false
                }
            }
          },
        }

        for (let i in dateList){
          //console.log(dateList[i])
          let date = this.unformatDate(dateList[i])
          
          if(this.shippingMethods[item].schedules && this.shippingMethods[item].schedules.data){
            console.log(date, this.shippingMethods[item].attributes.id_loja_integrada,this.shippingMethods[item].schedules.data.filter(el => el.attributes.schedule_date == date).length)
            this.shippingMethods[item].chart.apx_chartData.datasets[0].data.push(this.shippingMethods[item].schedules.data.filter(el => el.attributes.schedule_date == date).length)
          }
        }
        //console.log(this.shippingMethods)
    
      }
      this.showChart = true
      this.isLoading = false
    }
  },
  
  created(){
    let date = new Date()
    date.setDate(date.getDate() + 7)
    this.end_date = date.toISOString().slice(0,10),
    this.getShippingMethods()
  }

}
</script>

<style scoped>
  .filter input{margin-bottom: 0!important;}
  .panel{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    border-collapse: collapse;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 0px 5px 0px rgba(23, 24, 24, .05), 0px 1px 2px 0px rgba(0, 0, 0, .15);
    width: 100%;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    margin-bottom:1.5rem;
  }
  .panel span{
    font-weight: bold;
    font-size: 24px;
  }
  .panel small{
    color: #848484;
    font-size: 12px;
  }
</style>