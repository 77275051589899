<template>
  <LoadingSpinner :loading="isLoading || isLoadingForm || !schemaLoaded" />
  <div>
    <div class="row my-4">
      <div class="col">
        <h1 class="h3">{{ title }}</h1>
      </div>
      <div class="col-auto">
        <button v-if="permissions.create.enabled" :class="!schemaLoaded ? 'disabled btn btn-primary' : 'btn btn-primary'" type="submit" @click="newModalItem">Adicionar {{ singularTitle }}</button>
      </div>      
    </div>
    <div :class="isLoading ? 'loading row' : 'row'">
      <div class="col">
        <div id="TheListView" >
          <div id="ListHeader">

          </div>
          <div id="ListBody">
            <div id="ListItems">
              <div v-for="item in items.data" :key="item.id" class="ListRow">                
                <div v-if="permissions.find.enabled" class="ListItem w-0">
                  <button
                    :class="!schemaLoaded ? 'disabled btn btn-link btn-unstyle' : 'btn btn-link btn-unstyle'"
                    @click="showContent(item.id)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z"/>
                      <path fill-rule="evenodd" d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z"/>
                    </svg>
                  </button>
                </div>
                <div v-for="(field, key, index) in headers" :key="index" class="ListItem">
                  {{ getNestedValue(item, key) }}   
                </div>
                <div v-if="permissions.delete.enabled" class="ListItem justify-content-end text-right d-flex">
                  <button class="btn btn-sm btn-outline-danger"
                    @click="idToRemove = item.id">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                      <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
  <transition name="fade">
    <div v-if="modal">
      <div class="modal-backdrop fade show"></div>
      <div class="modal d-block" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{modalTitle}}</h5>
            </div>

            <div
              v-if="itemLoadedData && !editLoadedData"
              class="p-3"
            >
              <recursive-list :items="itemLoadedData.attributes" :schema="editSchema" />
            </div>
            <div v-if="itemLoadedData && itemLoadedData.attributes.order_id" class="p-3">
              <a class="btn btn-xs btn-primary" :href="'https://app.lojaintegrada.com.br/painel/pedido/' + itemLoadedData.attributes.order_id + '/detalhar'" target="_blank">
                Visualizar pedido na <b>Loja Integrada</b>
              </a>
            </div>
            <FormKit
                v-if="itemLoadedData && editLoadedData"
                type="form"
                id="currentModalForm"
                :actions="false"
                @submit="saveContent"
            >
              <div class="modal-body">
                <Suspense>
                  <FormKitSchema :schema="formSchema" />            
                </Suspense>
              </div>
              <div class="modal-footer">               
                <button type="button" class="btn btn-secondary" @click="closeModal">Cancelar</button>
                  <button 
                    v-if="currentAction == 'Post' || currentAction == 'Put'"
                    type="submit" class="btn btn-primary"
                  >
                  {{ currentAction == 'Post' ? 'Criar' : 'Atualizar' }} registro
                </button>        
              </div>
            </FormKit>
            <div class="modal-footer" v-if="itemLoadedData && !editLoadedData">     
                <button type="button" class="btn btn-secondary" @click="closeModal">Fechar</button>          
                <button type="button" class="btn btn-primary" 
                v-if="itemLoadedData && permissions.update.enabled"
                 @click="editContent()">Editar Registros</button>
            </div>
            <div v-if="!itemLoadedData">
              <FormKit
                  type="form"
                  id="currentModalForm"
                  :actions="false"
                  @submit="saveContent"
              >
                <div class="modal-body">
                  <FormKitSchema :schema="formSchema" />            
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" @click="closeModal">Cancelar</button>
                    <button 
                      v-if="currentAction == 'Post' || currentAction == 'Put'"
                      type="submit" class="btn btn-primary"
                    >
                    {{ currentAction == 'Post' ? 'Criar' : 'Atualizar' }} registro
                  </button>        
                </div>
              </FormKit>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>

  <transition name="fade">
    <div v-if="idToRemove">
      <div class="modal-backdrop fade show"></div>
      <div class="modal d-block" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Aviso</h5>
            </div>
            <div class="modal-body">
              <p>Tem certeza que deseja excluir este item? Essa ação não pode ser desfeita e todos os dados relacionados serão perdidos. Pense bem antes de prosseguir, pois uma vez deletado, não há volta.</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-light" @click="idToRemove = false">Cancelar</button>
              <button type="button" class="btn btn-danger" @click="removeItem(idToRemove)">Sim, prosseguir</button>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </transition>
 
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { createInput } from "@formkit/vue";
import CustomRepeater from "@/components/CustomRepeater.vue";
import { mapActions } from 'vuex';
import RecursiveList from '@/components/RecursiveList.vue';

const cRepeater = createInput(CustomRepeater,{props:['children','label','name','class']})

export default {
  components: {
    LoadingSpinner,
    RecursiveList

  },
  props: {
    title: String,
    description: String,
    apiPath: String,
    singularPath: String,
    singularTitle: String,
    params: Object,
    headers: Object,
    colOrder:Array
  },
  name: 'TheList',
  data(){
    return{
      modal: false,
      modalTitle: 'Adicionar ' + this.singularTitle,
      isLoading:false,
      isLoadingForm:false,
      permissions:this.$store.getters.loggedUserPermissions['api::' + this.singularPath].controllers[this.singularPath],
      cmsOptions: this.$store.getters.cmsOptions && this.$store.getters.cmsOptions.data && this.$store.getters.cmsOptions.data.find(el => el.apiID ==  this.singularPath) ? this.$store.getters.cmsOptions.data.find(el => el.apiID ==  this.singularPath).schema : false,
      cmsComponents:  this.$store.getters.cmsComponents ?? this.$store.getters.cmsComponents.data,
      formData : [],
      formSchema : [],
      formSchemaBase : [],
      editSchema : [],
      currentAction : 'Post',
      items:[],
      idToRemove:false,
      schemaLoaded : false,
      itemLoadedData:false,
      editLoadedData:false,

    }
  },
  methods: {
    ...mapActions(['performGet','performPost','performDelete','api_lang']),
    
    async saveContent(data){
      ////console.log(this.itemLoadedData)
      this.isLoadingForm = true

      let sendData = Object.assign({}, data);   
      
      sendData = await this.addMillisecondsToObject(sendData);      

      const finalData = await this.customRepeaterMount(this.formSchema, sendData)      
      
      let q = {
        path: this.apiPath,
        params: finalData
      }

      if(this.itemLoadedData){
        q.id = this.itemLoadedData.id
      }
      const response = await this.$store.dispatch('perform'+`${this.currentAction}`, q)      
      
      if(response.data){
        this.loadData()
        this.modal = false
        this.itemLoadedData = false
        this.editLoadedData = false
      }
      
      if(response.error){
        alert(response.error.message)
      }

      this.isLoadingForm = false
    },  
    
    async showContent(id){
      this.isLoadingForm = true
      
      const ctx = await this.$store.dispatch('performGet', {
        path: this.apiPath + '/' + id + '?populate=deep'
      })

      this.isLoadingForm = false

      if(ctx.data){
        this.itemLoadedData = ctx.data
        this.modalTitle = this.itemLoadedData.attributes.Nome || this.itemLoadedData.attributes.title || (this.itemLoadedData.attributes.order_id ? 'Pedido #' + this.itemLoadedData.attributes.order_id  : '')
        this.modal = true
      }else{
        this.itemLoaded = false
      }
    },

    async editContent(){
      this.isLoadingForm = true
      this.currentAction = 'Put'
      this.editSchema = [...this.formSchema]
      
      const editSchemaWithValues = await this.feedEditSchema(this.itemLoadedData.attributes, this.editSchema)
      
      //console.log(editSchemaWithValues)
      // this.editSchema
      // //console.log(this.itemLoadedData.attributes,this.editSchema)
      this.editSchema = editSchemaWithValues
      this.editLoadedData = true
      ////console.log(this.editSchema)
      this.isLoadingForm = false
    },

    async feedEditSchema(content, schema){
      for(let item in schema){
        if(schema[item] && schema[item].outerClass && schema[item].outerClass == "cRepeater"){
          let q = content[schema[item].name]
          if(q){
            schema[item].outerValues = q.data ? q.data : q
          }
        }else{
          //console.log('schema[item]:',schema[item])
          //console.log('schema[item]: 2',content[schema[item].name])
          if(content[schema[item].name] && content[schema[item].name].data){
            //console.log('entrou')
            schema[item].value = content[schema[item].name].data.map(item => item.id)
            //console.log('schema[item] after:',schema[item])
          }else{
            schema[item].value = content[schema[item].name]
          }
          
        }   
        if(schema[item].customTypper && schema[item].customTypper == 'boolean'){
          schema[item].value = schema[item].value.toString()
        }
        console.log('item:',schema[item] )
        console.log('valued:',schema[item].value )
      }
      
      return schema

    },

    async addMillisecondsToObject(obj){
      for (let key in obj) {
        let value = obj[key];
        if(typeof value === "string" && (value.split(':').length - 1) == 1){
        //if (typeof value === "string" && value.match(/\b\d{2}:\d{2}\b/)) {
          obj[key] = value + ':00.000';
        } else if (typeof value === "object" && value !== null) {
          obj[key] = await this.addMillisecondsToObject(value);
        }
      }
      return obj
    },
    async removeItem(id){
      this.isLoadingForm = true

      const response = await this.$store.dispatch('performDelete', {
        path: this.apiPath,
        params: id
      })
      
      if(response.data){
        this.idToRemove = false
        this.loadData()
      }

      if(response.error){
        alert(response.error.message)
      }

      this.isLoadingForm = false
    },  
  
    async newModalItem() {
      this.isLoadingForm = true
      this.modalTitle = 'Adicionar ' + this.singularTitle
      this.currentAction = 'Post'
      this.formSchema = await this.createSchemaItem(this.cmsOptions.attributes)
      this.isLoadingForm = false
      this.openModal()
    },

    openModal() {
      this.modal = true
    },

    closeModal() {
      this.editLoadedData = false
      this.itemLoadedData = false
      this.modal = false
    },

    async customRepeaterMount(data, sendData){
      let repeaters = [...data.filter(el => el.outerClass == "cRepeater")]
      for(let item of repeaters){
        let key = item.name        
        sendData[key] = []
        for(let repeater_key of Object.keys(sendData).filter(el => el.startsWith(key) && el != key)){
          let childRepeater = item.props[0].filter(el => el.outerClass == "cRepeater")
          if(childRepeater){
            sendData[repeater_key] = await this.customRepeaterMount(childRepeater, sendData[repeater_key])
          }
          sendData[key].push(sendData[repeater_key])
          delete sendData[repeater_key]
        } 
      }
      return sendData
    }, 

    async createSchemaItem(attributes){
      let schema = []
      for(const key in attributes){
        let label = await this.$store.dispatch('api_lang', {text:key})
        let me = attributes[key]
        
        if(['component','repeater'].includes(me.type)){  
          let repeaterOrGroup = me.repeatable ? 'repeater' : 'group'  
          let component = this.cmsComponents.data.find(el => el.uid == me.component)
          if(repeaterOrGroup == 'repeater'){
            schema.push({
              $formkit: cRepeater,
              name: key,
              label: label ? label : key,
              props:[await this.createSchemaItem(component.schema.attributes), key],
              outerClass:'cRepeater'
            })
          }else{
            schema.push({
              $formkit: repeaterOrGroup,
              name: key,
              label: label ? label : key,
              children: await this.createSchemaItem(component.schema.attributes)              
            })
          }
        }

        if(['relation'].includes(me.type)){
          let targetCmsOptions = this.$store.getters.cmsOptions && this.$store.getters.cmsOptions.data && this.$store.getters.cmsOptions.data.find(el => el.uid ==  me.target) ? this.$store.getters.cmsOptions.data.find(el => el.uid ==  me.target) : false
          
          if(targetCmsOptions){
            let response = await this.$store.dispatch('performGet', {
              path: targetCmsOptions.schema.pluralName,
              params: this.params
            })
            
            if(response.data){
              schema.push({
                $formkit: 'checkbox',
                help:'Selecione as opções desejadas',
                options: response.data.map(item => ({
                  value: item.id,
                  label: item.attributes.title || item.attributes.Nome,
                  help: item.attributes.description ?? ""
                })),
                name: key,
                label: label ? label : key
              })
            }
          }
        }

        if(['boolean'].includes(me.type)){
          schema.push({
            $formkit:'select',
            name: key,
            label: label ? label : key, 
            customTypper: 'boolean',
            options : {
              true: 'Sim',
              false: 'Não',              
            }   
          })
        }        
        
        if(['integer'].includes(me.type)){
          if(key == "day_of_week"){
            schema.push({
              $formkit:'select',
              name: key,
              label: label ? label : key,         
              options : {
                1: 'Segunda',
                2: 'Terça',    
                3: 'Quarta',    
                4: 'Quinta',    
                5: 'Sexta',    
                6: 'Sábado',    
                7: 'Domingo',              
              }       
            })
          }else{
            schema.push({
              $formkit:'number',
              name: key,
              label: label ? label : key,
              min:me.min,
              max:me.max       
            })  
          }
          
        }  

        if(['datetime'].includes(me.type)){
          schema.push({
            $formkit:'datetime-local',
            name: key,
            label: label ? label : key,
            min:me.min,
            max:me.max       

          })
        }  

        if(['text'].includes(me.type)){
          schema.push({
            $formkit:'textarea',
            name: key,
            label: label ? label : key,
            min:me.min,
            max:me.max       

          })
        }  

        if(['string','uid','time','date','email'].includes(me.type)){
          schema.push({
            $formkit:this.replaceFormKitType(me.type),
            name: key,
            label: label ? label : key,            
          })
        }        
      }
      
      return schema
    },

    replaceFormKitType(type){
      const replacedType = type.replace('string','text').replace('uid','text').replace('integer','number').replace('boolean','select')
      return replacedType
    },
    
    async loadData(){
      this.isLoading = true
      this.schemaLoaded = false
      
      const ctx = await this.$store.dispatch('performGet', {
        path: this.apiPath,
        params: this.params
      })
      this.items = ctx
      this.isLoading = false

      ////console.log(this.cmsOptions.attributes)
      this.formSchema = await this.createSchemaItem(this.cmsOptions.attributes)

      this.schemaLoaded = true
      
      
      
    },
    getNestedValue(obj, key) {
      if (key.includes('.')) {
        const keys = key.split('.');
        let value = obj;
        for (let i = 0; i < keys.length; i++) {
          value = value[keys[i]];
        }
        return value;
      } else {
        return obj[key];
      }
    }    
  },
  watch:{
    apiPath:function(){
      // //console.log(this.singularTitle)
      // //console.log(this.singularPath)
      // //console.log(this.apiPath)
      

      this.loadData()
      this.permissions = this.$store.getters.loggedUserPermissions['api::' + this.singularPath].controllers[this.singularPath]
      this.cmsOptions = this.$store.getters.cmsOptions && this.$store.getters.cmsOptions.data && this.$store.getters.cmsOptions.data.find(el => el.apiID ==  this.singularPath) ? this.$store.getters.cmsOptions.data.find(el => el.apiID ==  this.singularPath).schema : false
      this.cmsComponents =  this.$store.getters.cmsComponents ?? this.$store.getters.cmsComponents.data

      // //console.log(this.permissions)
      // //console.log(this.cmsOptions)
      // //console.log(this.cmsComponents)
    }
  },
  created(){
    this.loadData();
    console.log(this.permissions)
  }

}
</script>
<style scoped>
  #TheListView{
    --td-padding: 15px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    border-collapse: collapse;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 0px 5px 0px rgba(23, 24, 24, .05), 0px 1px 2px 0px rgba(0, 0, 0, .15);
    width: 100%;
  }
  #TheListView .ListItem{
    color: var(--body-color);
    display: table-cell;
    padding: 9px var(--td-padding);
    border-top: solid 1px var(--border-color);
    vertical-align: middle;
  }
  #TheListView .ListRow{
    display: table-row;
    width:100%
  }
  
  #ListBody #ListItems{
    display:table;
    width:100%;
  }



</style>
