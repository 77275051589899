<template>
  <nav  v-if="isLoggedIn" class="d-flex flex-column flex-shrink-0 p-3 bg-light" style="width: 280px;">
    <div class="mb-3 cover-logo text-center py-3">
      <a href="/" class="d-flex w-100 text-center justify-content-center align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
        <!-- <span class="fs-4">Fungo de Quintal</span> -->
        <img src="@/assets/logo.png" height="40"/>
      </a>
    </div>
    <ul class="nav nav-pills flex-column mb-auto w-100">
      <li>
        <router-link
        :class="$route.path.includes('dashboard') ? 'nav-link active' : 'nav-link'"
        to="/dashboard"
        >Dashboard</router-link>
      </li>
      <li v-for="(item, key) in cmsOptions" :key="key" class="nav-item">
        <router-link
        :class="$route.path.includes(item.apiID) ? 'nav-link active' : 'nav-link'"
        :to="item.apiID"
        >{{ item.schema.displayName }}</router-link>
      </li>      
    </ul>
    <hr>
    <div class="dropdown">
      <a href="#" class="d-flex align-items-center link-dark text-decoration-none dropdown-toggle" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
        <strong>{{ loggedUser.user.username }}</strong>
      </a>
      <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
        <!--<li><a class="dropdown-item" href="#">Settings</a></li>
        <li><a class="dropdown-item" href="#">Profile</a></li>
        <li><hr class="dropdown-divider"></li>-->
        <li><a class="dropdown-item" href="#" @click="logout">Sair</a></li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'TheNavigation',
  data() {
    return {
      
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    userPermissions() {
      return this.$store.getters.loggedUserPermissions;
    },
    cmsOptions() {
      return this.$store.getters.cmsOptions.data.filter(el => el.uid.includes('api::') && this.pathExist(el.apiID));
    }
  },
  methods: {
    ...mapActions(['logout']),
    pathExist(path){
      // console.log(path)
      // console.log(this.$router.options.routes)
      return this.$router.options.routes.find(route => route.path.replace('/','') === path) !== undefined;
    }
  },
  mounted() {
    
    // const token = localStorage.getItem('token')
    // if (token) {
    //   this.authenticated = true
    // } else {
    //   this.$router.push('/login')
    // }
  }
}
</script>

<style scoped>
  /* .cover-logo{
    background:#fff;
    border-radius:5px;
  } */

  nav{
    background:#F5F7FA;
    border-left:5px solid var(--green);
    border-right:1px solid #E5EAEF;
  }
  .btn-primary,
  .btn-primary:hover,
  .btn-primary:active,
  .btn-primary:visited{
    background:var(--green);
    border-color:var(--green);
    transition:.2s opacity;
  }
  .btn-primary:hover{opacity:.8}
  .nav-pills{
    --bs-nav-link-hover-color:var(--green);
    --bs-nav-pills-link-active-color:var(--green);
    --bs-nav-pills-link-active-bg: #E5EAEF;
    --bs-nav-link-color:#1C4D4B

  }
  .nav-pills .nav-link{font-weight:600;font-size:14px;}
</style>
