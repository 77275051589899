<template>
  <div class="form-main">
    <div class="form-signin">
      <div
      class="text-center mb-4">
      <img src="@/assets/logo.png"/>
      </div>
      <form @submit.prevent="handleSubmit">
        <div class="form-floating">
          <input type="email" v-model="username" id="i_username" class="form-control"/>
          <label for="i_username">E-mail:</label>
        </div>
        <div class="form-floating">
          <input type="password" v-model="password" id="i_password" class="form-control"/>
          <label for="i_password">Senha:</label>
        </div>
      
        <button class="w-100 btn btn-lg btn-primary" type="submit">Entrar</button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'TheLogin',
  data() {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    ...mapActions(['login']),
    async handleSubmit(){
      this.$store.dispatch('login', {
        username: this.username,
        password: this.password
      })
    }    
  }
}
</script>
<style scoped>
.form-main {
  height: calc(100% + 4rem);
  padding:0;
  gap:0;
  margin:-2rem;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--green);
    --bs-btn-border-color: var(--green);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--green);
    --bs-btn-hover-border-color: var(--green);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--green);
    --bs-btn-active-border-color: var(--green);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--green);
    --bs-btn-disabled-border-color: var(--green);
}

body,
.form-main {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;

}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>