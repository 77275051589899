<template>
    <ul v-if="loaded" class="repeaterList">        
        <li v-for="(item, index) in items" :key="index">
            <div v-if="typeof index == 'number' || index == 'data' || index == 'attributes'" 
            :class="(['object','array'].includes(typeof item) && expandedItems.includes(index) ? 'visible' : '') + ' repeaterItem ' + (['object','array'].includes(typeof item) ? 'repeaterItemToggle' : '')">
                <div class="row">
                    <div class="col-10">
                        <p class="repeaterListJsonTitle">{{ JSON.stringify(item) }}</p>
                    </div>
                    <div class="col-2">
                        <button
                        @click="toggleItem(index)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </button>
                    </div>
                    <div class="col-12" v-show="expandedItems.includes(index)">
                        <recursive-list v-if="['object','array'].includes(typeof item)" :items="item" />
                    </div>
                </div>
                
            </div>
            <div class="block" v-else>  
                <div v-if="labels[index] && (item != null && item != undefined)"
                :class="(['object','array'].includes(typeof item) && expandedItems.includes(index) ? 'visible' : '') + ' repeaterItem ' + (['object','array'].includes(typeof item) ? 'repeaterItemToggle' : '')">
                    <div class="row">
                        <div class="col-10">
                            <label>{{ labels[index] }} <span class="badge badge-info" v-if="['object','array'].includes(typeof item)">{{ item.data ? item.data.length : item.length }}</span></label>
                        </div>
                        <div v-if="['object','array'].includes(typeof item)" class="col-2">
                            <button
                            @click="toggleItem(index)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </button>
                        </div>
                        <div class="col-12" v-show="expandedItems.includes(index)">
                            <recursive-list v-if="['object','array'].includes(typeof item)" :items="item" />
                        </div> 
                    </div> 
                                      
                    <p v-if="!['object','array'].includes(typeof item)" >
                        {{ item }}
                    </p>
                </div>
            </div>
            
        </li>
        
        
    </ul>
  </template>
  
  <script>
  import { mapActions } from 'vuex';
  export default {
    name: 'RecursiveList',
    props: {
      items: Array,
      schema: Array
    },
    data(){
        return{
            labels:[],
            loaded: false,
            expandedItems: []
        }
    },
    methods:{
        ...mapActions(['api_lang']),
        async getApiLabels(){
            for(let k in this.items){
                this.labels[k] = await this.$store.dispatch('api_lang', {text:k})
            }

            console.log(this.labels)
            console.log(this.items)
        },
        toggleItem(index) {
            console.log(index)
            const idx = this.expandedItems.indexOf(index)
            if (idx === -1) {
                this.expandedItems.push(index)
            } else {
                this.expandedItems.splice(idx, 1)
            }
            console.log(this.expandedItems)
        }
    },
    created(){
        console.log(this.items)
        this.getApiLabels()
        this.loaded = true
    }
  };
  </script>
  <style scoped>
  
  .repeaterList{
    list-style-type: none;
    padding:0
  }
  .repeater-list
  .repeaterList .pl-3{padding-left:15px!important}
  .repeaterList > li > div.block{}
  .repeaterList > li > div.block > div{}
  .repeaterList .col-10 > label{
    font-weight:bold;
    font-size: 12px;
  }
  .repeaterList > li > div.block > div > p{}

  .repeaterList .repeaterItem.repeaterItemToggle .repeaterItem.repeaterItemToggle{padding:5px 0}
  .repeaterList .repeaterItem.repeaterItemToggle{
    background:#f8f8f8;
    margin-bottom:1rem;
    padding: 5px 10px;
    border-radius:5px;
  }
  .repeaterList .repeaterItem.repeaterItemToggle .repeaterItem.repeaterItemToggle{margin-bottom:0}
  .repeaterList .repeaterItem.repeaterItemToggle > .row > .col-2 button{
    border:0;
    background: transparent;
    padding:0;
  }
  .repeaterList .repeaterItem.repeaterItemToggle > .row > .col-2 {text-align: right;}
  .repeaterList .repeaterItem.repeaterItemToggle.visible > .row > .col-2 button svg{rotate:180deg}
  .repeaterList .repeaterItem.repeaterItemToggle.visible > .row > .col-12{margin-top:1rem}

  .repeaterList .repeaterItem.repeaterItemToggle .repeaterItem.repeaterItemToggle.visible > .row > .col-12{margin-top:0}
  .repeaterListJsonTitle{
    box-sizing: content-box!important;
    overflow: hidden;
    z-index: 1;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    min-height: unset!important;
    font-style: italic;
    font-weight: 400;
    font-size:12px;
    margin:0
  }

  .badge-info{
    background:var(--green)
  }
  </style>