import { createRouter, createWebHistory } from 'vue-router'
import TheLogin from './views/TheLogin.vue'
import TheDashboard from './views/TheDashboard.vue'
import TheList from './views/TheList.vue'
import authMiddleware from '@/middleware/authMiddleware'
import store from './store'

const isLoggedIn = (to, from, next) => {
    if (!store.getters.isLoggedIn) {
      next()
      return
    }
    next({ name: 'Dashboard' })
  }

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: TheLogin,
    beforeEnter: isLoggedIn
  },
  {
    path: '/logout',
    name: 'Logout',
    component: TheDashboard,
    meta: {
        middleware: [authMiddleware]
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: TheDashboard,
    meta: {
        middleware: [authMiddleware]
    }
  },
  {
    path: '/formas-de-envio',
    name: 'Formas de Envio',
    component: TheList,
    meta: {
        middleware: [authMiddleware]
    },
    props:{
        title:'Formas de Envio',
        apiPath:'formas-de-envios',
        singularPath:'formas-de-envio',
        singularTitle:'forma de envio',
        params:{
          // "populate"  : "*",
          // "[populate][0]" : "shipping_rules"
        },
        headers:{
          "attributes.Nome" : "Nome",          
        },
        
    }
  },
  {
    path: '/regra-prioritaria',
    name: 'Regras Prioritárias',
    component: TheList,
    meta: {
        middleware: [authMiddleware]
    },
   
    props:{
      title:'Regras Prioritárias',
      apiPath:'regras-prioritarias',
      singularPath:'regra-prioritaria',
      singularTitle:'regra prioritária',
      params:{
        // "populate"  : "*",
        // "[populate][0]" : "shipping_methods"
      },
      headers:{
        "attributes.title" : "Título",
        "attributes.description" : "Descrição",
        "attributes.date" : "Data Alvo"
      },      
    }
  },
  {
    path: '/agendamento',
    name: 'Agendamento',
    component: TheList,
    meta: {
        middleware: [authMiddleware]
    },
    
    props:{
      title:'Agendamentos',
      apiPath:'agendamentos',
      singularPath:'agendamento',
      singularTitle:'agendamento',
      params:{
        "sort[0]" : "createdAt:desc"      
      },
      headers:{
        "attributes.order_id" : "Núm. Pedido",
        "attributes.client_name" : "Cliente",
        "attributes.schedule_date" : "Agendado Para:",        
      },      
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
    if(!store.getters.isLoggedIn && to.name != "Login"){
        return next({name:'Login'})
    }else{
        if (to.meta.middleware) {
            const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
            const ctx = { from, next, router, to }
            return middleware[0]({ ...ctx, next: middlewarePipeline(ctx, middleware, 1) })
        }
        return next()
    }
})

function middlewarePipeline(context, middleware, index) {
    const nextMiddleware = middleware[index]

    if (!nextMiddleware) {
        return context.next
    }

    return () => {
        const nextPipeline = middlewarePipeline(context, middleware, index + 1)
        nextMiddleware({ ...context, next: nextPipeline })
    }
}

export default router
