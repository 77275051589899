<template>
  <div id="apx_main">
    <TheNavigation/>
    <main>
      <LoadingSpinner :loading="isLoading" />
      <div class="container h-100">
        <router-view/>
      </div>      
    </main>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import TheNavigation from '@/components/TheNavigation.vue'

export default {
  name: 'App',
  components: {
    TheNavigation,
    LoadingSpinner
  },
  data(){
    return {
      isLoading:false
    }
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    stopLoading() {
      //setTimeout(() => {
        this.isLoading = false;
      //}, 500);
    }
  },
  mounted() {
    this.$router.beforeEach(() => {
      this.startLoading();
      //next();
    });

    this.$router.afterEach(() => {
      this.stopLoading();
    });
  }
}
</script>

<style scoped>
  #apx_main{
    display:flex;
    font-size:16px;
    background:#F8F9FA;
  }
  #apx_main > nav{height:100vh;position: sticky;top:0}
  #apx_main main{
    width:100%;
    padding:2rem;
    min-height:100vh;

  }
  
</style>
<style>
  :root{
    --green:#009E6C;
    --green-hover:#009E6C;
    --body-color:#131313;
    --border-color:#e5eaef;
    --bs-primary: var(--green);
    --bs-blue: #ccc!important;
  }
  .btn-primary,
  .btn-primary:hover,
  .btn-primary:active,
  .btn-primary:visited{
    background:var(--green);
    border-color:var(--green);
    transition:.2s opacity;
  }
  .btn-primary:hover{opacity:.8}
  .nav-pills{
    --bs-nav-pills-link-active-bg: var(--green)
  }

 

</style>