<script setup>
    import { defineProps, ref, reactive } from 'vue'

    
    const props = defineProps({
        context: Object,
    })
    // console.log(props.context)
    // console.log(props.context.attrs.props[0])
    // console.log(props.context.attrs.props[1])

    
    
    // eslint-disable-next-line
    const schema = ref([{
        $formkit: 'group',
        name: props.context.attrs.props[1] + '[1]',
        label: props.context.handlers.label,
        children:[...props.context.attrs.props[0],
            {
            $el: 'button',
            attrs: {
                onClick: '$removeRow',
                class:'remBtn',
                type:'button',
                item:1
            },
            children: 'Remover',        
        }],
        outerClass:'cRepeater'
    }])
    
    
    // eslint-disable-next-line
    const removeRow = (e) => {
        let i = e.target.getAttribute('item')
        // console.log(i)
        if(schema.value.length - 1 == 0){
            return false
        }else{
            delete schema.value[i - 1]
            schema.value.length = schema.value.length - 1

            for(let i in schema.value.length){
                schema.value[i].name = props.context.attrs.props[1] + '[' + i + ']'
                schema.value[i].children[1].attrs.item = i
            }
        }        
    }

    const addRow = () => {
        schema.value.push(
            {
                $formkit: 'group',
                name: props.context.attrs.props[1] + '[' + (schema.value.length + 1) + ']',
                label: props.context.handlers.label,
                children:[...props.context.attrs.props[0],
                        {
                        $el: 'button',
                        attrs: {
                            onClick: '$removeRow',
                            class:'remBtn',
                            type:'button',
                            item: schema.value.length + 1
                        },
                        children: 'Remover',        
                        }
                    ],
                outerClass:'cRepeater'
            }
        )
    }

    if(props.context.attrs.outerValues){
        //console.log('props',props.context.attrs)
        schema.value.splice(0,schema.value.length)
        let values = props.context.attrs.outerValues.data ? props.context.attrs.outerValues.data : props.context.attrs.outerValues
        //console.log('wee')
        //console.log('VALUES:',values)
        for(let i in values){
            const setPropValue = reactive([...props.context.attrs.props[0],
                {
                    $el: 'button',
                    attrs: {
                        onClick: '$removeRow',
                        class:'remBtn',
                        type:'button',
                        item: schema.value.length + 1
                    },
                    children: 'Remover',        
                }
            ])
            
            //let customProps = [...setPropValue]

            for(let item of Object.keys(values[i])){
                let q = setPropValue.find(el => el.name == item)
                //console.log('values['+ i + ']['+ item +']', values[i][item])
                                
                const objetoIndex = setPropValue.findIndex(el => el.name === item);
                //console.log(objetoIndex)
                if (objetoIndex !== -1) {
                    //console.log('antes',setPropValue[objetoIndex])
                    if(q && q.outerClass == "cRepeater"){
                        setPropValue[objetoIndex] = {...setPropValue[objetoIndex], 'outerValues':values[i][item]}
                    }else if(q){
                        setPropValue[objetoIndex] = {...setPropValue[objetoIndex], 'value':values[i][item]}                        
                    }                           
                    //console.log('depois',setPropValue[objetoIndex])
                }                
            }

            //console.log('SETPROPVALUE',setPropValue)
            //setTimeout(function(){
                schema.value.push(
                    {
                        $formkit: 'group',
                        name: props.context.attrs.props[1] + '[' + (schema.value.length + 1) + ']',
                        label: props.context.handlers.label,
                        children:setPropValue,
                        outerClass:'cRepeater'
                    }
                )
            //}, 1000);
            
            
            
        }

        //console.log('final schema',schema)
    }

    const data = reactive({
        removeRow,
        addRow
    })
   
</script>

<template>   
    
    <div class="w-100 cRepeater-area">
        <div class="p-3">
            <FormKitSchema :schema="schema" :data="data"/>
        </div>
    </div>
    <div class="w-100">
        <div class="cRepeater-buttons">
            <div class="col-12">
                <button type="button" 
                @click="addRow"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .cRepeater-buttons{
        background:#f2f2f2;
        display: flex;
        text-align: center;
    }
    .cRepeater-buttons button{
        border: 0;
        width: 100%;
        height: 100%;
        display: block;
        padding:1em
    }
    .cRepeater-buttons button:hover{
        background: #ccc;
    }

</style>