// import { createApp } from 'vue'
// import App from './App.vue'
// import router from './router'
// import store from './store'
// import Vuetify from 'vuetify'
// import 'vuetify/dist/vuetify.min.css'

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap'



// createApp(App)
//   .use(router)
//   .use(store)
//   .use(Vuetify)
//   .mount('#app')

// eslint-disable-next-line
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { plugin, defaultConfig, createInput } from '@formkit/vue'

import 'bootstrap/dist/css/bootstrap.css'

import 'bootstrap'


import '@formkit/themes/genesis'

import CustomRepeater from "@/components/CustomRepeater.vue";
//import 'vuetify/styles'
// import { createVuetify } from 'vuetify'
// import * as components from 'vuetify/components'
// import * as directives from 'vuetify/directives'

// const vuetify = createVuetify({
//   components,
//   directives,
// })

const app = createApp(App)


app
  .use(router)
  .use(store)
  .use(plugin, defaultConfig({
    inputs: {
      otp: createInput(CustomRepeater, {
        
      }),
    },
  }))
  //.use(vuetify)
  .mount('#app')
