/* eslint-disable */
import { createApp } from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import strapi from '@/services/strapi';
import router from './router'

const app = createApp({});
app.use(Vuex);

const api_labels = {
  zip_ranges: "Faixas de CEP",
  override_rules: "Regras Prioritárias",
  Nome: "Nome",
  id_loja_integrada: "ID E-commerce",
  shipping_rules: "Regras de Envio",
  day_of_week: "Dia da Semana",
  zip_start:"CEP Inicial",
  zip_end:"CEP Final",
  active:"Ativo?",
  name:"Nome",
  estimated_time_start:"Horário de Início",
  estimated_time_end:"Horário de Término",
  periods:"Períodos",
  schedule_until_hour: "Antecedência (Horas)",
  shipping_methods: "Formas de Envio",
  block_delivery: "Bloquear Entrega?",
  title: "Título",
  date: "Data",
  order_id: "ID do Pedido",
  schedule_date: "Data do Agendamento",
  schedule_time: "Horário do Agendamento",
  client_name: "Cliente",
  schedule_date_time: "Data e Hora do Agendamento",
  update_on_erp: "Atualizado no ERP?",
  client_mail: "E-mail do Cliente",
  description: "Descrição"
}


export default new Vuex.Store({
  state: {
    user: null,
    permissions: null,
    cms:null,
    components:null
  },
  
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions
    },
    SET_CMS(state, cms) {
      state.cms = cms
    },
    SET_COMPONENTS(state, components) {
      state.components = components
    }
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const ctx = await strapi.login(credentials.username, credentials.password)
        if(ctx.jwt){
            commit('SET_USER', ctx)
            const ctx_permissions = await strapi.getPermissions(ctx)
            if(ctx_permissions){
              commit('SET_PERMISSIONS', ctx_permissions)
              const ctx_components = await strapi.getComponents(ctx)
              if(ctx_components){
                commit('SET_COMPONENTS', ctx_components)                
                const ctx_cms = await strapi.getContentType(ctx)
              
                if(ctx_cms){
                  commit('SET_CMS', ctx_cms)
                  return router.push({ name: 'Dashboard' });
                }
              }
            }
        }else{
            alert(ctx.error.message)
        }
      } catch (error) {
        //console.log(error)
      }
    },
    async performGet({commit},data) {
      //console.log(commit)
      try {
        const ctx = await strapi.performGet(this.state.user,data.path, data.params)
        return ctx
      }catch (error) {
        //console.log(error)
      }
    },
    async performPost({commit},data) {
      //console.log(commit)
      try {
        const ctx = await strapi.performPost(this.state.user,data.path, data.params)
        return ctx
      }catch (error) {
        //console.log(error)
      }
    },
    async performPut({commit},data) {
      //console.log(commit)
      try {
        const ctx = await strapi.performPut(this.state.user,data.path, data.params,data.id)
        return ctx
      }catch (error) {
        //console.log(error)
      }
    },
    async performDelete({commit},data) {
      //console.log(commit)
      try {
        const ctx = await strapi.performDelete(this.state.user,data.path, data.params)
        return ctx
      }catch (error) {
        //console.log(error)
      }
    },
    logout({ commit }){
        commit('SET_USER', null)
        router.push({ name: 'Login' })
    },
    api_lang({commit}, data){
       //console.log(commit)
      // //console.log(data)
      return api_labels[data.text]
    }
  },
  getters: {
    isLoggedIn: state => !!state.user,
    loggedUser: state => state.user,
    loggedUserPermissions: state => state.permissions,    
    cmsOptions: state => state.cms,
    cmsComponents: state => state.components
  },
  plugins: [
    createPersistedState()
  ]
})
