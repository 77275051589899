import axios from 'axios'
const strapi = {
    API_URL: 'https://cmsfungodequintal-production.up.railway.app/api',
    
    async getPermissions (user) {
        return axios.get(`${this.API_URL}/users/me?populate=role`, {
            headers: {
            Authorization: `Bearer ${user.jwt}`
            }
        }).then(response => {
            return axios.get(`${this.API_URL}/users-permissions/roles/${response.data.role.id}`, {
                headers: {
                Authorization: `Bearer ${user.jwt}`
                }
            }).then(response_ => {
                return response_.data.role.permissions
            })
            
        }).catch(error => {
            console.log(error)
            return []
        })
    },

    async getContentType (user) {
        return axios.get(`${this.API_URL}/content-type-builder/content-types`, {
            headers: {
            Authorization: `Bearer ${user.jwt}`
            }
        }).then(response => {
            return response.data
        }).catch(error => {
            console.log(error)
            return []
        })
    },

    async getComponents (user) {
        return axios.get(`${this.API_URL}/content-type-builder/components`, {
            headers: {
            Authorization: `Bearer ${user.jwt}`
            }
        }).then(response => {
            return response.data
        }).catch(error => {
            console.log(error)
            return []
        })
    },

    async performGet (user,endpoint,params) {
        return axios.get(`${this.API_URL}/${endpoint}`, {
            headers: {
                Authorization: `Bearer ${user.jwt}`
            },
            params: params
        }).then(response => {
            return response.data
        }).catch(error => {
            console.log(error)
            return []
        })
    },


    async performPost (user,endpoint,params) {
        return axios.post(`${this.API_URL}/${endpoint}`, {data:params}, {
            headers: {
                Authorization: `Bearer ${user.jwt}`
            }
        }).then(response => {
            return response.data
        }).catch(error => {
            console.log(error)
            return []
        })
    },

    async performPut (user,endpoint,params,id) {
        return axios.put(`${this.API_URL}/${endpoint}/${id}`, {data:params}, {
            headers: {
                Authorization: `Bearer ${user.jwt}`
            }
        }).then(response => {
            return response.data
        }).catch(error => {
            console.log(error)
            return []
        })
    },

    async performDelete (user,endpoint,params) {
        console.log(`${this.API_URL}/${endpoint}/${params}`)
        return axios.delete(`${this.API_URL}/${endpoint}/${params}`, {
            headers: {
                Authorization: `Bearer ${user.jwt}`
            }
        }).then(response => {
            return response.data
        }).catch(error => {
            console.log(error)
            return []
        })
    },

    
    async login (username, password) {       
        const response = await fetch(`${this.API_URL}/auth/local`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({
            identifier: username,
            password: password
            })
        })
        const data = await response.json()
        
        if (data.jwt) {
            localStorage.setItem('token', data.jwt)
        }

        return data;
    },

    
}

export default strapi